// @flow
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Column from './Column'
import reorder, { reorderQuoteMap } from '../reorder'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import ConfidenceListModal from 'views/metrix/confidencelistmodal'
import axios from 'axios'
import { useContext } from 'react'
import { ViewContext, useLocation } from 'components/lib'
import { useEffect } from 'react'

const Board = ({ isCombineEnabled, initial, useClone, containerHeight, withScrollableColumns, callBack }) => {
	const [columns, setColumns] = useState(initial)
	const [ordered, setOrdered] = useState(Object.keys(initial))
	const [confidenceModel, setConfidenceModel] = useState(false)
	const [experimentId, setExperimentId] = useState(false)
	const [dragResult, setDragResult] = useState('')
	const [onCancel, setOnCancel] = useState(false)
	const context = useContext(ViewContext)
	const [loading, setLoading] = useState(false)
	const router = useLocation()
	let productId = router.pathname.replace('/draganddrop/', '')
	let allData = columns?.Planned?.concat(columns?.OnGoing, columns?.Finished)

	useEffect(() => {
		if (onCancel) {
			const source = dragResult?.destination
			const destination = dragResult?.source
			if (source && destination) {
				const data =
					source &&
					destination &&
					reorderQuoteMap({
						quoteMap: columns,
						source,
						destination
					})
				setOnCancel(false)
				setColumns(data.quoteMap)
			}
			setOnCancel(false)
			context.modal.hide(true)
		}
	}, [onCancel])

	const onDragEnd = (result) => {
		setDragResult(result)
		setExperimentId(result?.draggableId)
		if (result?.destination?.droppableId == 'Finished') {
			setConfidenceModel(true)
		} else {
			UpdateExperimentModal(result?.destination?.droppableId, result?.draggableId)
		}

		if (result.combine) {
			if (result.type === 'COLUMN') {
				const shallow = [...ordered]

				shallow.splice(result.source.index, 1)
				setOrdered(shallow)
				return
			}

			const column = columns[result.source.droppableId]
			const withQuoteRemoved = [...column]

			withQuoteRemoved.splice(result.source.index, 1)

			const orderedColumns = {
				...columns,
				[result.source.droppableId]: withQuoteRemoved
			}
			setColumns(orderedColumns)
			return
		}

		// dropped nowhere
		if (!result.destination) {
			return
		}

		const source = result.source
		const destination = result.destination

		// did not move anywhere - can bail early
		if (source.droppableId === destination.droppableId && source.index === destination.index) {
			return
		}

		// reordering column
		if (result.type === 'COLUMN') {
			const reorderedorder = reorder(ordered, source.index, destination.index)
			setOrdered(reorderedorder)
			return
		}

		const data = reorderQuoteMap({
			quoteMap: columns,
			source,
			destination
		})

		setColumns(data.quoteMap)
	}

	const finishExperiment = async (isChangeStatus, value, id) => {
		let status = isChangeStatus == 'Planned' ? 'Scheduled' : isChangeStatus == 'OnGoing' && 'Ongoing'
		let risk = status == false ? allData?.find((e) => (e?.id == experimentId ? experimentId : id)) : null

		await axios({
			method: 'put',
			url: `/api/product_experiment/${experimentId ? experimentId : id}`,
			data: {
				status: isChangeStatus ? status : 'Finished',
				risk_reduction: value && risk ? (parseFloat(risk?.author?.hypothesis_id?.risk) * value?.confidence).toFixed(2) : 0,
				confidence: value ? value?.confidence : 0,
				real_end_date: value?.realEndDate ? value?.realEndDate : undefined,
				insight: value?.insight,
				support: value.support,
				refute: value.refuse,
				unclear: value.unClear
				// draggedItems: draggedItems
			}
		})
			.then(async (res) => {
				if (res.data) {
					console.log('isChangeStatus', isChangeStatus)
					if (value?.learning && (isChangeStatus != 'ONGoing' || isChangeStatus != 'Planned')) {
						await axios({
							method: 'post',
							url: `/api/experiment_learning`,
							data: { product_id: productId, experiment_id: experimentId, text: value?.learning }
						})
					}

					context.notification.show(res.data.message, 'success', true)
					setTimeout(() => {
						window.location.reload()
					}, 200)
					callBack()
					context.modal.hide(true)
					setConfidenceModel(false)

				}
			})
			.catch((e) => {
				if (e?.response?.data?.message) {
					setConfidenceModel(false)
				}
			})
	}

	function UpdateExperimentModal(value, id) {
		context.modal.show({
			title: 'Change Experiment Status?',
			body: 'Are you sure to change the experiment status?',
			delete: true,
			buttonText: 'Update?',
			cancel: 'Cancel',
			submit: () => finishExperiment(value, '', id),
			onCancel: () => setOnCancel(true),
			loading: loading,
			destructive: false
		})
	}

	return (
		<>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="board" type="COLUMN" direction="horizontal" ignoreContainerClipping={Boolean(containerHeight)} isCombineEnabled={isCombineEnabled}>
					{(provided) => (
						<div className="flex flex-wrap" ref={provided.innerRef} {...provided.droppableProps}>
							{ordered.map((key, index) => (
								<Column
									key={key}
									index={index}
									title={key}
									quotes={columns[key]} //isScrollable={withScrollableColumns}
									isCombineEnabled={isCombineEnabled}
									useClone={useClone}
									callBack={callBack}
								/>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>

			{confidenceModel && (
				<ConfidenceListModal
					onClose={() => {
						setOnCancel(true)
						setConfidenceModel(false)
					}}
					// data={quote?.author}
					confidence={(e) => {
						console.log('=========e-----', e)
						finishExperiment(false, e)
					}}
					/>
			)}
		</>
	)
}

Board.defaultProps = {
	isCombineEnabled: false
}

Board.propTypes = {
	isCombineEnabled: PropTypes.bool
}

export default Board
