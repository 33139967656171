import React, { useState } from 'react';
import {Form, Button} from "../../../components/lib";
import axios from 'axios';
import settings from 'settings';

// Card component that takes a title, form field, and an update handler
const CardForm = ({ product_id, field, label, integration, getIntegrations, icon, checkAllIntegrations, isAllCollapsed, onToggleCollapse, rowIndex, onRowToggle}) => {
    const [isUpdated, setIsUpdated] = useState(false);
    const [value, setValue] = useState('');
    const [isCollapsed, setIsCollapsed] = useState(true);

    // Update local collapse state when parent state changes
    React.useEffect(() => {
        setIsCollapsed(isAllCollapsed);
    }, [isAllCollapsed]);

    const key = integration && integration[0] && `is${field[0].toUpperCase() + field.slice(1)}`; // Constructs 'isStatus'
    let valueOfVariable = integration && integration[0] && integration[0][key]; // Accesses the dynamically created key
    console.log('==== valueOfVariable', valueOfVariable);
    let placeholder;
    let placeholder2;
    let placeholder3;
    switch(label){
        case 'Google Analytics': placeholder = 'Enter your Google Analytics Property ID'; break;
        case 'Amplitude': 
            placeholder = 'Enter Project ID';
            placeholder2 = 'Enter API Key';
            placeholder3 = 'Enter Secret Key';
            break;
        case 'Hotjar': 
            placeholder = 'Enter Client ID';
            placeholder2 = 'Enter Client Secret';
        break;
        case 'Mailchimp': 
            placeholder = 'Enter API Key';
            break;
        case 'Mixpanel': 
            placeholder = 'Enter Service Username';
            placeholder2 = 'Enter Secret';
            break;
        default: placeholder = 'Enter your API Key';
    }

    const handleInputChange = (e) => {
        setValue(e.target.value);
        setIsUpdated(true); // Change to true to toggle the icon to green
    };

    const oauth_dialog = () => {
        const SM_API_BASE = "https://api.surveymonkey.com";
        const AUTH_CODE_ENDPOINT = "/oauth/authorize";
        const redirect_uri = `${settings[process.env.NODE_ENV].server_url}/api/surveymonkey/oauth/`;
        const client_id = '9iIxGEg7SRK2pSFK0banzQ';
        const response_type = 'code';
        const state = product_id;

        console.log('==== oauth_dialog');
        var auth_dialog_uri = SM_API_BASE + AUTH_CODE_ENDPOINT + "?redirect_uri=" + redirect_uri + "&client_id=" + client_id + "&response_type=" + response_type + "&state=" + state;
        window.location.href = auth_dialog_uri;
    }

    const toggleCollapse = () => {
        const newState = !isCollapsed;
        setIsCollapsed(newState);
        onToggleCollapse(newState);
        onRowToggle(rowIndex, newState);
    };

    console.log('integration', integration);
    return (
        <div className={`card bg-white shadow-lg rounded-lg p-6 border border-gray-200 transition-all duration-300 hover:shadow-xl ${
            !valueOfVariable
            ? 'opacity-50' 
            : ''
        }`}>
            {/* Card Header */}
            <div className="card-header flex flex-col space-y-3 mb-4">
                <div className="flex justify-between items-center cursor-pointer" onClick={toggleCollapse}>
                    <div className="flex items-center gap-3">
                        <div className="w-10 h-10 flex items-center justify-center bg-gray-50 rounded-lg">
                            <img src={icon} alt={label} className="w-6 h-6 object-contain" />
                        </div>
                        <h2 className="text-xl font-semibold text-gray-800">{label}</h2>
                    </div>
                    <div className="flex items-center gap-2">
                        <a onClick={(e) => {
                            e.stopPropagation();
                            checkAllIntegrations();
                        }} className="flex cursor-pointer items-center bg-gray-50 px-3 py-1.5 rounded-full">
                            <div className={`w-3 h-3 rounded-full mr-2 ${
                                valueOfVariable
                                ? 'bg-green-500 animate-pulse' 
                                : 'bg-red-500'
                            }`} />
                            <span className={`text-sm font-medium ${
                                valueOfVariable
                                ? 'text-green-600'
                                : 'text-red-600' 
                            }`}>
                                {valueOfVariable
                                 ? 'Connected' 
                                 : 'Not Connected'}
                            </span>
                        </a>
                        <svg 
                            className={`w-6 h-6 transform transition-transform ${isCollapsed ? '' : '-rotate-180'}`}
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                {!isCollapsed && integration && integration[0] && 
                    <>
                        {(() => {
                            switch(label) {
                                case 'Mailchimp':
                                    return (
                                        <div className="text-sm text-gray-600 font-medium px-3 py-2 bg-gray-50 rounded-lg overflow-hidden text-ellipsis">
                                            <b>API Key:</b> { integration[0].mailchimp_api_key }
                                        </div>
                                    );
                                case 'Amplitude':
                                    return (
                                        <div className="text-sm text-gray-600 font-medium px-3 py-2 bg-gray-50 rounded-lg overflow-hidden text-ellipsis">
                                            <b>Project ID:</b> {integration[0].amplitude_project_id}<br/>
                                            <b>API Key:</b> {integration[0].amplitude_api_key}<br/>
                                            <b>Secret Key:</b> {integration[0].amplitude_secret_key}
                                        </div>
                                    );
                                case 'Mixpanel':
                                    return (
                                        <div className="text-sm text-gray-600 font-medium px-3 py-2 bg-gray-50 rounded-lg overflow-hidden text-ellipsis">
                                            <b>Username:</b> {integration[0].mixpanel_username}<br/>
                                            <b>Secret:</b> {integration[0].mixpanel_secret}
                                        </div>
                                    );
                                case 'Hotjar':
                                    return (
                                        <div className="text-sm text-gray-600 font-medium px-3 py-2 bg-gray-50 rounded-lg overflow-hidden text-ellipsis">
                                            <b>Client ID:</b> {integration[0].hotjar_client_id}<br/>
                                            <b>Client Secret:</b> {integration[0].hotjar_client_secret}
                                        </div>
                                    );
                                default:
                                    return (
                                        <div className="text-sm text-gray-600 font-medium px-3 py-2 bg-gray-50 rounded-lg overflow-hidden text-ellipsis">
                                            <b>API Key:</b> {integration[0][field] || 'Not Connected'}
                                        </div>
                                    );
                            }
                        })()}
                    </>
                }
            </div>
            {
            field === 'surveymonkey' &&
            <a className="p-3 bg-[#6145F7] hover:!bg-[#422db6] rounded-3xl text-white rounded-lg cursor-pointer" onClick={() => {
                oauth_dialog();
            }}>
                🔑 Connect Survey Monkey
            </a>
            }
            {!isCollapsed && label && <Form
                inputs={(() => {
                    switch(label) {
                        case 'Amplitude':
                            return {
                                [`${field}_project_id`]: {
                                    id: `${field}_project_id`,
                                    label: 'Project ID',
                                    showLabel: false,
                                    defaultValue: integration[0]?.amplitude_project_id,
                                    placeholder: placeholder,
                                    type: 'text', 
                                    required: true,
                                    errorMessage: 'Please enter the Project ID'
                                },
                                [`${field}_api_key`]: {
                                    id: `${field}_api_key`,
                                    label: 'API Key',
                                    showLabel: false,
                                    defaultValue: integration[0]?.amplitude_api_key,
                                    placeholder: placeholder,
                                    type: 'text', 
                                    required: true,
                                    errorMessage: 'Please enter the API Key'
                                },
                                [`${field}_secret_key`]: {
                                    id: `${field}_secret_key`,
                                    label: 'Secret Key',
                                    showLabel: false,
                                    type: 'text', 
                                    required: true,
                                    defaultValue: integration[0]?.amplitude_secret_key,
                                    placeholder: placeholder2,
                                    errorMessage: 'Please enter the Secret Key'
                                }
                            };
                        case 'Hotjar':
                            return {
                                [`${field}_client_id`]: {
                                    id: `${field}_client_id`,
                                    label: 'Client ID',
                                    showLabel: false,
                                    type: 'text', 
                                    required: true,
                                    defaultValue: integration[0]?.hotjar_client_id,
                                    placeholder: placeholder,
                                    errorMessage: 'Please enter the Client ID'
                                },
                                [`${field}_client_secret`]: {
                                    label: 'Client Secret',
                                    showLabel: false,
                                    type: 'text',
                                    required: true,
                                    defaultValue: integration[0]?.hotjar_client_secret,
                                    placeholder: placeholder2,
                                    errorMessage: 'Please enter the Client Secret'
                                }
                            };
                        case 'Mixpanel':
                            return {
                                [`${field}_username`]: {
                                    id: `${field}_username`,
                                    label: 'Username',
                                    showLabel: false,
                                    type: 'text', 
                                    required: true,
                                    defaultValue: integration[0]?.mixpanel_username,
                                    placeholder: placeholder,
                                    errorMessage: 'Please enter the Project Token'
                                },
                                [`${field}_secret`]: {
                                    label: 'Secret',
                                    showLabel: false,
                                    type: 'text',
                                    required: true,
                                    defaultValue: integration[0]?.mixpanel_secret,
                                    placeholder: placeholder2,
                                    errorMessage: 'Please enter the API Token'
                                }
                            };
                        default:
                            return {
                                [field]: {
                                    showLabel: false,
                                    type: 'text',
                                    required: true,
                                    defaultValue: integration && integration[0] && integration[0][field],
                                    placeholder: placeholder,
                                    errorMessage: 'Please enter the API Key'
                                }
                            };
                    }
                })()}
                url={`/api/integration/${product_id}`}
                method='PUT'
                buttonText='Save'
                callback={(res) => {
                    getIntegrations();
                    alert('Updated successfully');
                }}
                className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
            />}
        </div>)
}

// Main component that renders each form in a grid layout
const IntegrationForms = ({product_id, integration, getIntegrations}) => {
    const [isAllCollapsed, setIsAllCollapsed] = useState(true);
    const [rowStates, setRowStates] = useState([]);
    
    const formFields = [
        { field: 'optimizely', label: 'Optimizely', icon: 'https://www.rudderstack.com/integration/_next/image/?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2F97bpcflt%2Fproduction%2F90d44122f4613b945d9ebc41e373beba975f82bb-83x80.svg%3Fw%3D83%26fm%3Dwebp%26fit%3Dfill%26dpr%3D2&w=2048&q=75' },
        { field: 'surveymonkey', label: 'Survey Monkey', icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReQ_wDq6zJrb_yxCuSlO6az4-H7i0eKQBbsw&s' },
        { field: 'unbounce', label: 'Unbounce', icon: 'https://cdn.worldvectorlogo.com/logos/unbounce.svg' },
        { field: 'metabusinessmanager', label: 'Meta Business Manager', icon: 'https://www.facebook.com/images/fb_icon_325x325.png' },
        { field: 'amplitude', label: 'Amplitude', icon: 'https://amplitude.com/favicon.ico' },
        { field: 'googleanalytics', label: 'Google Analytics', icon: 'https://www.vectorlogo.zone/logos/google_analytics/google_analytics-icon.svg' },
        { field: 'mixpanel', label: 'Mixpanel', icon: 'https://cdn.brandfetch.io/idr_rhI2FS/theme/dark/idMJ8uODLv.svg?k=id64Mup7ac&t=1717143401811' },
        { field: 'hotjar', label: 'Hotjar', icon: 'https://www.hotjar.com/favicon.ico' },
        { field: 'segment', label: 'Segment', icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROVrtUgi2SUOV5r45Muk1mbixjlaNUvycTBA&s' },
        { field: 'mailchimp', label: 'Mailchimp', icon: 'https://cdn.worldvectorlogo.com/logos/mailchimp-freddie-icon.svg' },
        { field: 'tealium', label: 'Tealium', icon: 'https://www.tealium.com/favicon.ico' },
        { field: 'rudderstack', label: 'Rudderstack', icon: 'https://rudderstack.com/favicon.ico' },
        { field: 'appcues', label: 'Appcues', icon: 'https://cdn.icon-icons.com/icons2/2699/PNG/512/appcues_logo_icon_170540.png' },
        { field: 'kissmetrics', label: 'Kissmetrics', icon: 'https://cdn.worldvectorlogo.com/logos/kissmetrics.svg' },
        { field: 'webflow', label: 'Webflow', icon: 'https://webflow.com/favicon.ico' },
        { field: 'leadpages', label: 'Leadpages', icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7l9Tl53z-ZYnlM6IV8NB3r7JaNaV85xmO0Q&s' },
    ];

    const checkAllIntegrations = async () => {
        console.log('==== checkAllIntegrations');
        const response = await axios.get(`/api/integration/${product_id}/check`);
        console.log('==== response', response);
        getIntegrations()
    };

    const handleToggleAll = (newState) => {
        setIsAllCollapsed(newState);
        setRowStates(new Array(Math.ceil(formFields.length / 3)).fill(newState));
    };

    const handleRowToggle = (rowIndex, newState) => {
        const newRowStates = [...rowStates];
        newRowStates[rowIndex] = newState;
        setRowStates(newRowStates);
    };

    return (
        <>  
            <div className="bg-grey-800 p-6 m-6 mt-0 rounded-lg shadow-lg">
                <h1 className="text-2xl font-bold mb-3">🔑 Integration is key</h1>
                <p className="">Breathe some consciousness into your hypotheses and experiments and help us to make realistic risk, innovation and innovation performance predictions</p>
            </div>

            <div className="flex gap-4 mx-6">
                <a className="p-3 bg-[#6145F7] hover:!bg-[#422db6] rounded-3xl text-white rounded-lg cursor-pointer" onClick={checkAllIntegrations}>
                    🕵️ Check All Integrations
                </a>
                <a className="p-3 bg-[#6145F7] hover:!bg-[#422db6] rounded-3xl text-white rounded-lg cursor-pointer" onClick={() => setIsAllCollapsed(!isAllCollapsed)}>
                    {isAllCollapsed ? '🔽 Expand All' : '🔼 Collapse All'}
                </a>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-6">
                {formFields
                    .sort((a, b) => {
                        const aKey = `is${a.field[0].toUpperCase() + a.field.slice(1)}`;
                        const bKey = `is${b.field[0].toUpperCase() + b.field.slice(1)}`;
                        const aValue = integration && integration[0] && integration[0][aKey];
                        const bValue = integration && integration[0] && integration[0][bKey];
                        // Sort connected (true) items first
                        return (bValue ? 1 : 0) - (aValue ? 1 : 0);
                    })
                    .map((field, index) => (
                        <CardForm 
                            getIntegrations={getIntegrations} 
                            integration={integration} 
                            checkAllIntegrations={checkAllIntegrations} 
                            icon={field.icon} 
                            key={field.field} 
                            field={field.field} 
                            label={field.label} 
                            product_id={product_id}
                            isAllCollapsed={rowStates[Math.floor(index / 3)] ?? isAllCollapsed}
                            onToggleCollapse={handleToggleAll}
                            rowIndex={Math.floor(index / 3)}
                            onRowToggle={handleRowToggle}
                        />
                    ))
                }
            </div>

        </>
    );
};

export default IntegrationForms;
