import { Table, ViewContext } from 'components/lib'
import React from 'react'
import { CSSTransition } from 'react-transition-group'
import csvJson from '../../app/csvjson.json'

const ExperimentTypeModel = ({ onClose, onSelect, setectedValue }) => {
	return (
		<div>
			<CSSTransition in appear timeout={0} classNames="modal p-8 overflow-hidden">
				<div className="modal p-8">
					{/* onClick={onClose} */}
					<div className="modal-content-experiment-type  overflow-auto !open">
						<div className="flex justify-between overflow-hidden">
							<div className="flex justify-end overflow-hidden">{/* <div className="font-bold text-center text-[1.2rem] flex justify-end">Select Experiment Type</div> */}</div>
							<div className="font-bold text-center text-[1.2rem] flex justify-end mb-2 cursor-pointer" onClick={onClose}>
								x
							</div>
						</div>

						<Table data={csvJson} selectedValue={(e) => onSelect(e)} defaultValue={setectedValue} parent={true} />
					</div>
				</div>
			</CSSTransition>
		</div>
	)
}

export default ExperimentTypeModel
