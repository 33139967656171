import { ExperimentCard } from 'components/card/projectcard'
import { Button, Card, DateInput, Grid, Loader, NumberInput, Select, Switch, TextInput, useLocation, ViewContext } from 'components/lib'
import Items from 'components/items/items'
import Spacer from 'components/spacer/spacer'
import { CSSTransition } from 'react-transition-group'
import { useContext, useEffect, useState } from 'react'
import '../../components/modal/modal.scss'
import axios from 'axios'
import ConfidenceListModal from './confidencelistmodal'
import ExperimentTypeModel from './ExperimentTypeModel'
import { Input } from 'reactstrap'

export const UpdateExperiment = (props) => {
	const context = useContext(ViewContext)
	const router = useLocation()
	const [name, setName] = useState('')
	const [integrationMetricName, setIntegrationMetricName] = useState('')
	const [integrationMetricGoal, setIntegrationMetricGoal] = useState('')
	const [integrationExperimentId, setIntegrationExperimentId] = useState('')
	const [description, setDescription] = useState('')
	const [cost, setCost] = useState('')
	const [experimentType, setExperimentType] = useState('')
	const [status, setStatus] = useState('Scheduled')
	const [learning, setLearning] = useState('')
	const [insight, setInsight] = useState(false)
	const [realEndDate, setRealEndDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [integrationOptions, setIntegrationOptions] = useState([])
	const [openModel, setOpenModel] = useState(false)
	const [validExperimentType, setValidExperimentType] = useState(true)
	const [validName, setValidName] = useState(true)
	const [validCost, setValidCost] = useState(true)
	const [validDescription, setValidDescription] = useState(true)
	const [validEndDate, setValidEndDate] = useState(true)
	const [itemsOrders, setItemOrder] = useState('')
	const [isDraged, setIsDraged] = useState(false)
	const [draggedItems, setDraggedItems] = useState([])
	const [isEdit, setIsEdit] = useState('')
	const [experimentTypeOptions, setExperimentTypeOptions] = useState([])
	const [experimentTypeModels, setExperimentTyeModels] = useState(false)
	const [experimetnObject, setExperimentObject] = useState()
	const [loading, setLoading] = useState(false)
	let id = router.pathname.replace('/hypothesis/', '')


	useEffect(() => {
		getAllExperimentTypes()
		if (props?.isUpdate) {
			getExperiment(props?.isUpdate?.id)
		}
	}, [props?.isUpdate])

	const getExperiment = async (id) => {
		setLoading(true)
		await axios({
			method: 'get',
			url: `/api/product_experiment/${id}`,
		}).then(res => {
			if (res?.data) {
				let _data = res?.data?.data
				console.log('=======res?.data', res?.data)
				setName(_data?.name)
				setDescription(_data?.description)
				setCost(_data?.cost)
				setExperimentType(_data?.experiment_type)
				setExperimentObject({ 'Experiment Type': _data?.experiment_type })
				setEndDate(_data?.end_date)
				setStatus(_data?.status)
				setRealEndDate(_data?.real_end_date)
				setInsight(_data?.insight == 1 ? true : false)

				let arr = []
				let Arr = []

				_data?.learning?.length &&
					_data?.learning?.map((e) => {
						// arr.push(e)
						if (e?.children?.length) {
							e?.children?.map((res) => {
								if (res?.children?.length) {
									res?.children?.map((child) => {
										if (child.children?.length) {
											arr.push(...child.children)
										}
									})
									res?.children?.length && arr.push(...res?.children)
								}
							})
							Arr.push(...e?.children)
						} else {
							arr.push(e)
						}
					})

				// console.log('arr=========', arr, Arr)
				let data = arr.concat(Arr)
				let newArr = arr.concat(Arr)

				for (let h = 0; h < data.length; h++) {
					var curItem = data[h].id
					var foundCount = 0
					// search array for item
					for (let i = 0; i < data.length; i++) {
						if (data[i].id == data[h].id) foundCount++
					}
					if (foundCount > 1) {
						// remove repeated item from new array
						for (let j = 0; j < newArr.length; j++) {
							if (newArr[j].id == curItem) {
								newArr.splice(j, 1)
								j--
							}
						}
					}
				}

				setItemOrder(newArr)
				setLoading(false)
			}
		}).catch(err => {
			setLoading(false)
		})
	}

	const now = new Date()

	const date = {
		year: now.getFullYear(),
		month: now.getMonth() + 1,
		day: now.getDate()
	}
	function formatDateString(d) {
		return `${d.year}-${d.month < 10 ? '0' + d.month : d.month}-${d.day < 10 ? '0' + d.day : d.day}`
	}

	const addExperiments = async (isFinished, value) => {
		if (status == 'Finished' && isFinished == false && handleValidation() && props?.isUpdate?.status != 'Finished') {
			setOpenModel(true)
		} else {
			if (handleValidation()) {
				await axios({
					method: props?.isUpdate ? 'put' : 'post',
					url: props?.isUpdate ? `/api/product_experiment/${props?.isUpdate?.id}` : '/api/product_experiment',
					data: {
						cost: cost,
						id: props?.isUpdate ? undefined : { product_id: id, hypothesis_id: props?.hypothesis?.id },
						name: name,
						description,
						status,
						end_date: endDate,
						start_date: formatDateString(date),
						experiment_type: experimentType,
						risk_reduction: props?.isUpdate?.risk_reduction ? props?.isUpdate?.risk_reduction : 0,
						// draggedItems: draggedItems
						experiment_type_id: experimentType,
						integration: experimetnObject?.Integration,
						integration_experiment_id: integrationExperimentId,
						integration_metric_name: integrationMetricName,
						integration_metric_goal: integrationMetricGoal
					}
				})
					.then(async (res) => {
						if (res.data) {
							if (status == 'Finished' && isEdit == '' && isDraged == false && learning) {
								await axios({
									method: 'post',
									url: `/api/experiment_learning`,
									data: { product_id: id, experiment_id: props?.isUpdate ? props?.isUpdate?.id : res.data?.data?.id, text: learning, children: isDraged && itemsOrders }
								})
							}

							if (status == 'Finished' && (isEdit || isDraged)) {
								await axios({
									method: 'put',
									url: `/api/experiment_learning/${itemsOrders[0]?.id}`,
									data: { children: itemsOrders }
								})
							}

							context.notification.show(res.data.message, 'success', true)
							props?.callBack()
							context.modal.hide(true)
						}
					})
					.catch((e) => {
						if (e?.response?.data?.message) {
							setOpenModel(false)
							// context.modal.hide(true)
							// context.notification.show(e?.response?.data?.message, 'error', true)
						}
					})
			}
		}
	}

	const handleValidation = () => {
		let isValid = true
		if (experimentType?.trim().length === 0) {
			isValid = false
			setValidExperimentType(false)
		} else {
			setValidExperimentType(true)
		}

		if (description?.trim().length === 0) {
			isValid = false
			setValidDescription(false)
		} else {
			setValidDescription(true)
		}

		if (name?.trim().length === 0) {
			isValid = false
			setValidName(false)
		} else {
			setValidName(true)
		}

		if (endDate?.trim().length === 0) {
			isValid = false
			setValidEndDate(false)
		} else {
			setValidEndDate(true)
		}

		if (cost?.trim().length === 0) {
			isValid = false
			setValidCost(false)
		} else {
			setValidCost(true)
		}

		// if (status == 'Finished' && learning.trim().length === 0) {
		// 	isValid = false;
		// 	setValidLearning(false)
		// } else {
		// 	setValidLearning(true)
		// }

		return isValid
	}

	const fetchIntegrationOptions = async (type) => {
		const options = await axios.get(`/api/integrationoptions/${type}/product/${id}`)
		console.log('==== options', options);
		setIntegrationOptions(options.data || [])
	}

	const deleteLearning = async (id) => {
		try {
			// setLoading(true)
			const res = await axios({
				method: 'Delete',
				url: `/api/experiment_learning/${id}`
			})
			if (res.data) {
				// setLoading(false)
				if (res.data.message) context.notification.show(res.data.message, 'success', true)
				props?.callBack()
				context.modal.hide(true)
			}
		} catch (error) {
			// setLoading(false)
			context.handleError(error)
		}
	}

	const getAllExperimentTypes = async (value) => {
		await axios({
			method: 'get',
			url: `/api/experiment_type`,
			data: { query: value }
		})
			.then((res) => {
				if (res.data) {
					let arr = []
					if (res.data.data?.length) {
						res.data.data?.map((data) => {
							arr.push({ label: data?.experiment_type, value: data?.id })
						})
					}
					setExperimentTypeOptions(arr)
				}
			})
			.catch((err) => { })
	}
	const isIntegrationType = () => {
		const isIntegrationType = experimetnObject?.Integration && ['mailchimp', 'surveymonkey', 'unbounce', 'googleanalytics', 'mixpanel', 'metabusinessmanager'].includes(experimetnObject.Integration);
		
		return isIntegrationType;
	}

	return (
		<>
			<CSSTransition in appear timeout={0} classNames="modal">
				<div className="modal fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center" 
					 onClick={(e) => e.target === e.currentTarget && context.modal.hide(true)}>
					{loading && <Loader />}
					<div className={`modal-content-experiment bg-indigo-100 text-indigo-900 rounded-lg shadow-xl p-8 max-w-4xl w-full mx-4 ${openModel == true ? '' : 'max-h-[90vh] overflow-y-auto'}`}>
						<h1 className="text-2xl font-bold text-indigo-900 mb-6">Experiment</h1>
						<Spacer height="h-4" />
						<h2 className="text-sm font-semibold text-indigo-700 uppercase tracking-wider mb-4">HYPOTHESIS</h2>
						<ExperimentCard hypothesis={props?.hypothesis} />
						<Card className="bg-indigo-200 shadow-md rounded-lg p-6 mt-6">
							<Grid cols={1} className="gap-6">
								<TextInput
									onClick={() => setExperimentTyeModels(true)}
									label="Experiment Type"
									type="text"
									value={experimentType}
									valid={experimentType ? true : validExperimentType}
									required="true"
									className="focus:ring-2 focus:ring-indigo-500 bg-indigo-50 text-indigo-900"
								/>
								<TextInput
									label="Name"
									type="text"
									value={name}
									max={256}
									valid={name ? true : validName}
									required="true"
									onChange={(name, value, valid) => {
										setName(value)
									}}
									className="focus:ring-2 focus:ring-indigo-500 bg-indigo-50 text-indigo-900"
								/>
								<TextInput
									label="Description"
									type="textarea"
									value={description}
									required="true"
									valid={description ? true : validDescription}
									onChange={(name, value, valid) => {
										setDescription(value)
									}}
									className="focus:ring-2 focus:ring-indigo-500 bg-indigo-50 text-indigo-900"
								/>

								<NumberInput
									label="Cost"
									type="number"
									value={cost}
									valid={cost ? true : validCost}
									required="true"
									min={0}
									onChange={(name, value, valid) => {
										setCost(value)
									}}
									className="focus:ring-2 focus:ring-indigo-500 bg-indigo-50 text-indigo-900"
								/>

								{isIntegrationType() &&
								<div className="space-y-6">
									<h2 className="text-sm font-semibold text-indigo-700 uppercase tracking-wider">INTEGRATION</h2>
									<hr className="border-indigo-300" />
									<TextInput
										label="Integration"
										type="picker"
										disabled={true}
										required="true"
										value={experimetnObject?.Integration}
										className="bg-indigo-50 text-indigo-900"
									/>
									<Select
										defaultValue={integrationOptions?.[0]?.experiment_id}
										label="Integration Experiment ID"
										type="select"
										options={Array.isArray(integrationOptions) ? integrationOptions.map(e => ({ label: e.experiment_name, value: e.experiment_id })) : []}
										required="true"
										value={integrationExperimentId}
										onChange={(name, value, valid) => {
											setIntegrationExperimentId(value)
										}}
										className="focus:ring-2 focus:ring-indigo-500 bg-indigo-50 text-indigo-900"
									/>
									<Select
										label="Integration Metric Name"
										type="select"
										options={Array.isArray(integrationOptions) && integrationOptions.find(e => e.experiment_id === integrationExperimentId)?.metrics ?
											Object.keys(integrationOptions.find(e => e.experiment_id === integrationExperimentId).metrics).map(key => ({
												label: key,
												value: key
											})) : []
										}
										value={integrationMetricName}
										required="true"
										onChange={(name, value, valid) => {
											setIntegrationMetricName(value)
										}}
										className="focus:ring-2 focus:ring-indigo-500 bg-indigo-50 text-indigo-900"
									/>
									<TextInput
										label={`Metric Goal: ${integrationMetricName} (current: ${integrationOptions?.find(e => e.experiment_id === integrationExperimentId)?.metrics[integrationMetricName]?.replace('_', ' ')?.replace('.', ' ')})`}
										type="number"
										required="true"
										value={integrationMetricGoal}
										onChange={(name, value, valid) => {
											setIntegrationMetricGoal(value)
										}}
										className="focus:ring-2 focus:ring-indigo-500 bg-indigo-50 text-indigo-900"
									/>
								</div>
								}

								<div className="flex justify-between gap-6">
									<Button
										className={`flex-1 py-3 px-4 rounded-lg transition-all duration-200 ${status == 'Scheduled' ? 'bg-indigo-300 text-indigo-900' : 'bg-indigo-50 text-indigo-700 hover:bg-indigo-200'}`}
										text="SCHEDULED  📅"
										action={() => setStatus('Scheduled')}
									/>
									<Button
										className={`flex-1 py-3 px-4 rounded-lg transition-all duration-200 ${status == 'Ongoing' ? 'bg-yellow-300 text-yellow-900' : 'bg-indigo-50 text-indigo-700 hover:bg-indigo-200'}`}
										text="ONGOING   🚧"
										action={() => setStatus('Ongoing')}
									/>
								</div>
							</Grid>

							<DateInput
								label="Planned end date"
								type="date"
								value={endDate}
								required="true"
								min={new Date()}
								valid={endDate ? true : validEndDate}
								onChange={(name, value, valid) => {
									setEndDate(value)
								}}
								className="mt-6 focus:ring-2 focus:ring-indigo-500 bg-indigo-50 text-indigo-900"
							/>
						</Card>

						{props?.isUpdate?.learning?.length && status == 'Finished' && (
							<>
								<Spacer height="h-6" />
								<Card className="bg-indigo-200 shadow-md rounded-lg p-6">
									<Items
										items={itemsOrders ? itemsOrders : props?.isUpdate?.learning}
										order={(e) => {
											setIsDraged(true)
											setItemOrder(e?.items)
											draggedItems.push(e?.dragItem)
										}}
										edit={(e, val) => {
											if (val == true) {
												props?.callBack()
												context.modal.hide(true)
												setIsEdit(val)
											}
										}}
										onDelete={(e) => {
											deleteLearning(e)
										}}
									/>
								</Card>
							</>
						)}

						<Spacer height="h-6" />
						<div className="flex justify-end gap-4">
							<Button 
								text="Close"
								color="red"
								className="px-6 py-2 rounded-lg border border-red-500 text-red-500 hover:bg-red-50"
								action={() => context.modal.hide(true)}
								outline
							/>
							<Button
								text={props?.isUpdate ? 'Update' : 'Save'}
								color="indigo"
								className="px-6 py-2 rounded-lg bg-indigo-500 text-white hover:bg-indigo-600"
								action={() => addExperiments(false)}
							/>
						</div>
					</div>
				</div>
			</CSSTransition>

			{experimentTypeModels && (
				<ExperimentTypeModel
					onClose={() => setExperimentTyeModels(false)}
					setectedValue={experimetnObject}
					onSelect={(e) => {
						setExperimentObject(e)
						setExperimentType(e['Experiment Type'])
						if(e?.Integration && ['mailchimp', 'surveymonkey', 'unbounce', 'googleanalytics', 'mixpanel', 'metabusinessmanager'].includes(e.Integration)){
							fetchIntegrationOptions(e.Integration)
						}
						setExperimentTyeModels(false)
					}}
				/>
			)}
		</>
	)
}
