import React, { Fragment, useEffect, useState } from 'react'
import { Accordion, AccordionBody } from '@material-tailwind/react'
import { Table, DropdownMenu } from 'components/lib'

// Custom arrow icon component with smooth rotation animation
function Icon({ id, open, onClick }) {
	return (
		<img 
			src="/icons/arrow_down.svg" 
			alt="Expand"
			className={`
				${id === open ? 'rotate-[360deg]' : 'rotate-[270deg]'}
				transition-transform duration-300 ease-in-out
				mr-2 cursor-pointer hover:opacity-80 filter drop-shadow-md
			`}
			onClick={onClick}
		/>
	)
}

export function Accordions(props) {
	const [open, setOpen] = useState(0)
	const [openRisk, setOpenRisk] = useState(0)
	const [users, setUsers] = useState([
		{ 
			Experiment: 'Landing Page',
			Cost: ' 3.000€',
			status: 'FINSIHED',
			Insight: <img src="/icons/green_icon.svg" className="w-[1.35rem] filter drop-shadow" alt="Success" />,
			Confidence: '80 %',
			'Risk Reduction': ' 14%'
		},
		{
			Experiment: 'Customer Interview',
			Cost: ' 3.000€', 
			status: 'FINSIHED',
			Insight: <img src="/icons/red_icon.svg" className="w-[1.35rem] filter drop-shadow" alt="Failure" />,
			Confidence: '80 %',
			Riskreduction: ' 14%'
		}
	])

	const handleOpen = (value) => {
		setOpen(open === value ? 0 : value)
	}

	useEffect(() => {
		let arr = []
		setOpenRisk(0)
		
		if (props?.experiments?.length) {
			props.experiments?.map((experiment, index) => {
				const isFinished = experiment?.status === 'Finished'
				
				arr.push({
					Experiment: experiment?.name,
					Cost: `${experiment?.cost}€`,
					status: experiment?.status,
					Insight: isFinished ? (
						experiment?.insight ? 
							<img src="/icons/green_icon.svg" className="w-[1.35rem] filter drop-shadow-lg" alt="Success" /> :
							<div className="w-[1.35rem] h-[1.35rem] rounded-full bg-red-500 shadow-md" alt="Failure" />
					) : (
						<div className="w-[1.35rem] h-[1.35rem] rounded-full bg-[#EBECF0] shadow-sm" alt="Pending" />
					),
					Confidence: isFinished ? `${experiment?.confidence ? parseFloat(experiment?.confidence).toFixed(2) : 0}%` : '',
					'Risk Reduction': isFinished ? `${experiment?.risk_reduction}%` : '',
					Actions: (
						<DropdownMenu
							finishTitle={'Finish Experiment'}
							onFinish={() => props?.onFinish(experiment)}
							onEdit={() => props.onEdit(experiment)}
							onDelete={() => props.onDelete(experiment.id)}
							editTitle={'Edit'}
							deleteTitle={'Delete'}
							parent={'cases'}
							last={index === props?.experiments?.length - 1}
						/>
					)
				})
			})

			const sum = props?.experiments.reduce((acc, exp) => (
				exp?.status === 'Finished' ? acc + exp.risk_reduction : acc
			), 0)

			setOpenRisk(parseFloat(sum))
		}
		setUsers(arr)
	}, [props?.experiments])

	return (
		<Fragment>
			<div className="bg-gradient-to-br from-white to-blue-50 p-6 hover:shadow-xl transition-all duration-300 ease-in-out rounded-lg border border-blue-100">
				<Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
					<div className="flex items-center justify-between">
						<div className="flex items-center">
							<Icon id={1} open={open} onClick={() => handleOpen(1)} />
							<h1 className="text-lg font-medium text-blue-900">{props?.text}</h1>
						</div>

						{props?.isTeamLead && (
							<div className="flex space-x-3">
								<img 
									src="/icons/pencil.svg" 
									className="w-5 h-5 cursor-pointer hover:opacity-70 transition-all hover:scale-110" 
									alt="Edit"
									onClick={props.edit}
								/>
								<img 
									src="/icons/basket.svg" 
									className="w-5 h-5 cursor-pointer hover:opacity-70 transition-all hover:scale-110" 
									alt="Delete"
									onClick={props.delete}
								/>
							</div>
						)}

						<div className="flex items-center space-x-4">
							<span className="text-gray-500">{props.risk}%</span>
							<span className="text-lg font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
								{!Number.isNaN(openRisk) ? openRisk.toFixed(1) : '0'}%
							</span>
						</div>
					</div>

					<AccordionBody>
						<Table
							className="restrict-width"
							data={users}
							loading={users?.loading}
							badge={{
								col: 'status',
								color: 'blue',
								condition: [
									{ value: 'FINSIHED', color: 'emerald' },
									{ value: 'invited', color: 'blue' }
								]
							}}
						/>

						{props?.isTeamLead && (
							<button 
								className="text-lg p-4 hover:bg-gradient-to-r hover:from-blue-50 hover:to-purple-50 transition-all duration-300 rounded-md w-full text-left text-blue-800 font-medium"
								onClick={props.newExperience}
							>
								🧪 New Experiment
							</button>
						)}
					</AccordionBody>
				</Accordion>
			</div>
		</Fragment>
	)
}
