import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Typewriter from 'typewriter-effect';

const ChatGPT = (props) => {
    const [response, setResponse] = useState('');

    const apiKey = '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';
    const apiUrl = 'https://api.openai.com/v1/engines/gpt-3.5-turbo-instruct/completions';

    useEffect(() => {
        handleChatGPTRequest()
    }, [])

    const handleChatGPTRequest = async () => {
        try {
            const requestData = {
                prompt: `You are product manager and want to de-risk your bus
                iness idea.
                The innovative product ${JSON.stringify(props?.product)} is based on an h
                ypothesis:${JSON.stringify(props?.hypothesis)}
                Which of the following experiments would you conduct
                to test your hypothesis?
                Customer Interview, Discussion Forums, Social Media
                Campaign, Referral Program, Expert Stakeholder, 3D P
                rint, Paper Prototype, Partner & Supplier Interview,
                A Day in the Life, Discovery Survey, Search Trend An
                alysis, Web Traffic Analysis, Sales Force Feedback,
                Customer Support Analysis, Online Ad, Link Tracking,
                404 Test, Feature Stub, Email Campaign, Storyboard,
                Data Sheet, Brochure, Explainer Video, Boomerang, Pr
                etend to Own, Product Box, Speed Boat, Card Sorting,
                Buy a Feature, Clickable Prototype, Single Feature M
                VP, Mash-Up, Concierge, Life-Sized Prototype, Simple
                Landing Page, Crowdfunding, A/B Test, Presale, Valid
                ation Survey, Wizard of Oz, Mock Sale, Letter of Int ent, Pop-Up Store, Give reasons for your choice. What are alternative experiments that are also helpf
                ul and why?
                Give me response in html tags and use style of tailwind css.
                `,
                max_tokens: 250,

            };

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}`,
            };

            const response = await axios.post(apiUrl, requestData, { headers });
            setResponse(response.data.choices[0].text);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: response }}>
                {/* <p>{response}</p> */}
                {/* {response && <Typewriter

                    cusror={''}
                    text="This is a typewriter effect!"
                    speed={10}
                    onInit={(typewriter) => {
                        typewriter
                            .typeString(`<p class='paragraph' contentEditable="true" id='editable-paragraph'>${response}</p>`)
                            .callFunction(() => {
                                typewriter.deleteAll()
                                typewriter.stop()
                            })
                            .start();


                    }}

                ></Typewriter>} */}
            </div>
        </div>
    );
};

export default ChatGPT;
