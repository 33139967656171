import PropTypes from 'prop-types'
import Style from '../../commit/components/hero/hero.module.scss'

const IntegrationLogos = ({className = ''}) => {
	return (
		<section className={`self-stretch flex flex-row items-start justify-start pt-0 px-6 pb-[40px] lg:pb-[120px] max-w-full text-center text-xl font-plus-jakarta-sans ${className}`}>
			<div className="flex-1 flex flex-col items-center justify-start gap-[48px] max-w-full">
				<div className="flex items-center gap-2">
					<span className="text-2xl font-bold relative leading-[120%] text-transparent !bg-clip-text [background:linear-gradient(98.81deg,_#825bf8,_#5f44f6_44.79%,_#5caff9_96.35%)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">
						Derisking Intelligence
					</span>
					<b className="relative leading-[120%] text-transparent !bg-clip-text [background:linear-gradient(98.81deg,_#825bf8,_#5f44f6_44.79%,_#5caff9_96.35%)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">
						based on experimental data collected from:
					</b>
				</div>
				<div className="self-stretch flex flex-col items-start justify-start max-w-full">
					<div className="grid grid-cols-3 md:grid-cols-6 gap-8 w-full px-4 md:px-8 lg:px-16">
						<a target="_blank" className="flex items-center justify-center" href="https://www.optimizely.com">
							<img src="https://www.rudderstack.com/integration/_next/image/?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2F97bpcflt%2Fproduction%2F90d44122f4613b945d9ebc41e373beba975f82bb-83x80.svg%3Fw%3D83%26fm%3Dwebp%26fit%3Dfill%26dpr%3D2&w=2048&q=75" alt="Optimizely" className="h-16 grayscale hover:grayscale-0 transition-all duration-300" />
						</a>
						<a target="_blank" className="flex items-center justify-center" href="https://unbounce.com">
							<img src="https://cdn.worldvectorlogo.com/logos/unbounce.svg" alt="Unbounce" className="h-16 grayscale hover:grayscale-0 transition-all duration-300" />
						</a>
						<a target="_blank" className="flex items-center justify-center" href="https://amplitude.com">
							<img src="https://amplitude.com/favicon.ico" alt="Amplitude" className="h-16 grayscale hover:grayscale-0 transition-all duration-300" />
						</a>
						<a target="_blank" className="flex items-center justify-center" href="https://www.facebook.com/business">
							<img src="https://axiom.ai/automate-images/meta-logo.png" alt="Meta Business Suite" className="h-16 grayscale hover:grayscale-0 transition-all duration-300" />
						</a>
						<a target="_blank" className="flex items-center justify-center" href="https://mixpanel.com">
							<img src="https://cdn.brandfetch.io/idr_rhI2FS/theme/dark/idMJ8uODLv.svg?k=id64Mup7ac&t=1717143401811" alt="Mixpanel" className="h-16 grayscale hover:grayscale-0 transition-all duration-300" />
						</a>
						<a target="_blank" className="flex items-center justify-center" href="https://mailchimp.com">
							<img src="https://cdn.worldvectorlogo.com/logos/mailchimp-freddie-icon.svg" alt="Mailchimp" className="h-16 grayscale hover:grayscale-0 transition-all duration-300" />
						</a>
					</div>
				</div>

				{/* <div className={Style.marquee}>
					<div className={Style.marqueeContent}>
						<a target="_blank" className={`${Style.newslink} ${Style.marqueeItem}`} href="http://entrepreneur.com">
							<img className={Style.newsimage} src="/news/entrepreneur.webp" alt="Derisky Featured: Entrepreneur Magazine Logo white " />
						</a>
						<a target="_blank" className={`${Style.newslink} ${Style.marqueeItem}`} href="http://bloomberg.com">
							<img className={Style.newsimage} src="/news/bloomberg.png" alt="Derisky Featured: Bloomberg Magazine Logo white " />
						</a>
						<a target="_blank" className={`${Style.newslink} ${Style.marqueeItem}`} href="http://forbes.com">
							<img className={Style.newsimage} src="/news/forbes.webp" alt="Derisky Featured: Forbes Magazine Logo white " />
						</a>
						<a target="_blank" className={`${Style.newslink} ${Style.marqueeItem}`} href="http://businessinsider.com">
							<img className={Style.newsimage} src="/news/businessinsider.webp" alt="Derisky Featured: Business Insider Magazine Logo white " />
						</a>
						<a target="_blank" className={`${Style.newslink} ${Style.marqueeItem}`} href="http://finance.yahoo.com">
							<img className={Style.newsimage} src="/news/yahoo-finance_white.png" alt="Derisky Featured: Yahoo Finance Magazine Logo white " />
						</a>
					</div>
				</div> */}
			</div>
		</section>
	)
}

IntegrationLogos.propTypes = {
	className: PropTypes.string
}

export default IntegrationLogos
