import React from 'react'
import {Draggable} from 'react-beautiful-dnd'
import QuoteList from '../styles/list'
import {RxDotsVertical} from 'react-icons/rx'
import Title from '../styles/title.tailwind'

const Style = {
	log: 'flex items-center justify-between border-t-4 border-[#FF6B6B] rounded bg-white shadow-md hover:shadow-lg transition-shadow duration-200',
	progress: 'flex items-center justify-between border-t-4 border-[#4ECDC4] rounded bg-white shadow-md hover:shadow-lg transition-shadow duration-200', 
	review: 'flex items-center justify-between border-t-4 border-[#45B7D1] rounded bg-white shadow-md hover:shadow-lg transition-shadow duration-200',
	complete: 'flex items-center justify-between border-t-4 border-[#96CEB4] rounded bg-white shadow-md hover:shadow-lg transition-shadow duration-200'
}

const Column = (props) => {
	const title = props.title
	const quotes = props.quotes
	const index = props.index
	return (
		<Draggable draggableId={title} index={index} isDragDisabled>
			{(provided, snapshot) => (
				<div className="flex flex-col m-2 w-[22%] xs:w-[100%] sm:w-[100%] md:w-[47%] lg:w-[21.5%] xl:w-[23%]" ref={provided.innerRef} {...provided.draggableProps}>
					<div
						className={title === 'Back Log' ? Style.log : title === 'In Progress' ? Style.progress : title === 'Review' ? Style.review : title === 'Complete' ? Style.complete : ''}
						isDragging={snapshot.isDragging}
						title={title}
						style={{
							backgroundColor: snapshot.isDragging ? '#E8F5E9' : null,
							padding: '12px'
						}}>
						<div 
							className={`${Title.title} font-medium text-gray-700`} 
							isDragging={snapshot.isDragging} 
							{...provided.dragHandleProps} 
							aria-label={`${title} quote list`}
						>
							{title}
						</div>
						{/* <RxDotsVertical className="text-gray-400 hover:text-gray-600 cursor-pointer" /> */}
					</div>
					<QuoteList
						listId={title}
						listType="QUOTE"
						style={{
							backgroundColor: snapshot.isDragging ? '#E8F5E9' : null
						}}
						quotes={quotes}
						internalScroll={props.isScrollable}
						isCombineEnabled={Boolean(props.isCombineEnabled)}
						useClone={Boolean(props.useClone)}
						callBack={props?.callBack}
					/>
				</div>
			)}
		</Draggable>
	)
}

export default Column
