import axios from 'axios'
import RangeSlider, { HypothesisCard } from 'components/card/projectcard'
import { Button, Card, DateInput, Grid, Icon, Select, Switch, TextInput, useLocation, ViewContext } from 'components/lib'
import Spacer from 'components/spacer/spacer'
import { useContext, useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import '../../components/modal/modal.scss'
import moment from 'moment'
import AssumptionMapping from "../../components/AssumptionMapping";

export const UpdateHypothesis = (props) => {
	const context = useContext(ViewContext)
	const router = useLocation()
	const [statement, setStatement] = useState('')
	const [description, setDescription] = useState('')
	const [phase, setPhase] = useState('')
	const [caseId, setCaseId] = useState('')
	const [businessCase, setBusinessCase] = useState([])
	const [importance, setImportance] = useState(50)
	const [unknown, setUnknown] = useState(50)
	const [risk, setRisk] = useState(15)
	const [validStatementField, setValidStatementField] = useState(true)
	const [validatePhase, setValidatePhase] = useState(true)
	const [validateDescription, setValidateDescription] = useState(true)
	const [isToggle, setIsToggle] = useState(false)
	const [start_date, setStart_date] = useState('')
	const [loading, setLoading] = useState(false)

	let id = router.pathname.replace('/hypothesis/', '')
	const divRef = useRef(null);
	const [divHeight, setDivHeight] = useState(0);

	useEffect(() => {
		if (divRef.current) {
			setDivHeight(divRef.current.getBoundingClientRect().height);
		}
	}, []);

	useEffect(() => {
		if (props?.isUpdate) {
			setIsToggle(props?.isUpdate?.case_id ? true : false)
			setStatement(props?.isUpdate?.statement)
			setDescription(props?.isUpdate?.description)
			setUnknown(props?.isUpdate?.unknown)
			setImportance(props?.isUpdate?.importance)
			setRisk(props?.isUpdate?.risk)
			setPhase(props?.isUpdate?.phase)
			setCaseId(props?.isUpdate?.case_id)
			setStart_date(props?.isUpdate?.date_created)
		}
	}, [])

	useEffect(() => {
		if (isToggle == false && !props?.isUpdate?.case_id) {
			setCaseId('')
		}
	}, [isToggle])

	useEffect(() => {
		getBusinessCase()
	}, [])

	const getBusinessCase = async () => {
		const res = await axios({
			method: 'get',
			url: `/api/all_business_case/${id}`
		})

		if (res.data) {
			let arr = []
			if (res.data.data?.length) {
				res.data.data?.map((e) => {
					arr.push({ label: e?.name, value: e?.id })
				})
			}
			setBusinessCase(arr)
		}
	}

	const addHypothesis = async () => {
		if (handleValidation()) {
			setLoading(true)
			await axios({
				method: props?.isUpdate ? 'put' : 'post',
				url: props?.isUpdate ? `/api/product_hypothesis/${props?.isUpdate?.id}` : `/api/product_hypothesis`,
				data: {
					risk: risk,
					phase: phase,
					id: props?.isUpdate ? undefined : id,
					case_id: caseId ? caseId : undefined,
					statement: statement,
					importance,
					unknown,
					description,
					date_created: moment(start_date).format('YYYY-MM-DD')
				}
			})
				.then((res) => {
					if (res.data) {
						context.notification.show(res.data.message, 'success', true)
						props?.callBack()
						context.modal.hide(true)
						setLoading(false)
					}
				})
				.catch((e) => {
					if (e?.response?.data?.message) {
						context.notification.show(e?.response?.data?.message, 'error', true)
					}
					setLoading(false)
				})
		}
	}

	const handleValidation = () => {
		let isValid = true
		if (statement?.trim().length === 0) {
			isValid = false
			setValidStatementField(false)
		} else {
			setValidStatementField(true)
		}

		if (description?.trim().length === 0) {
			isValid = false
			setValidateDescription(false)
		} else {
			setValidateDescription(true)
		}

		if (phase?.trim().length === 0) {
			isValid = false
			setValidatePhase(false)
		} else {
			setValidatePhase(true)
		}

		return isValid
	}

	return (
		<CSSTransition in appear timeout={0} classNames="modal" style={{ padding: -30}}>
			<div className="modal bg-gray-900/50 backdrop-blur-sm" onClick={(e) => e.target === e.currentTarget && context.modal.hide(true)}>
				<div className="modal-content-hypothesis overflow-auto bg-gradient-to-br from-white to-gray-50 shadow-2xl rounded-2xl">
					<div className="bg-gradient-to-r from-brand-400 to-brand-600 text-white p-6 rounded-t-2xl">
						<h1 className="text-2xl font-bold flex items-center gap-3">
							<Icon image='alert-circle' />
							Create Hypothesis
						</h1>
					</div>

					<div className="p-6">
						<div className="mb-8">
							<h2 className="text-xl font-semibold mb-4">A good hypothesis should be:</h2>
							<Grid cols="3" className="gap-6">
								<HypothesisCard image="/Add/hypo_1.png" title="Testable" subtitle="Can be proven with evidence as true or false" />
								<HypothesisCard image="/Add/hypo_2.png" title="Precise" subtitle="Clear definition of success criteria" />
								<HypothesisCard image="/Add/hypo_3.png" title="Discrete" subtitle="Focus on one testable element" />
							</Grid>
						</div>

						<Card className="bg-white shadow-lg rounded-xl">
							<div className="space-y-6">
								<div className="bg-gradient-to-r from-gray-50 to-gray-100 p-4 rounded-lg">
									<h3 className="text-lg font-semibold mb-4">Basic Information</h3>
									<Grid cols="1" className="gap-4">
										<DateInput
											label="Start Date"
											type="date"
											value={start_date != 'Invalid date' ? start_date : ''}
											min={new Date()}
											onChange={(name, value) => setStart_date(value)}
											className="bg-white"
										/>
										<TextInput
											label="Statement"
											type="text"
											placeholder="We believe that..."
											value={statement}
											max={256}
											required="true"
											valid={statement ? true : validStatementField}
											onChange={(name, value) => setStatement(value)}
											className="bg-white"
										/>
										<TextInput
											label="Description"
											type="textarea"
											value={description}
											required="true"
											valid={description ? true : validateDescription}
											onChange={(name, value) => setDescription(value)}
											className="bg-white"
										/>
									</Grid>
								</div>

								<div className="bg-gradient-to-r from-gray-50 to-gray-100 p-4 rounded-lg">
									<div className="flex justify-between items-center mb-4">
										<h3 className="text-lg font-semibold">Classification</h3>
										<Switch
											label="Add to Case"
											hypothesis={true}
											default={isToggle}
											onChange={(name, value) => {
												if (value == false) setCaseId('')
												setIsToggle(value)
											}}
										/>
									</div>
									<Grid cols="2" className="gap-4">
										<Select
											label="Case"
											options={businessCase}
											default={caseId}
											disabled={!isToggle}
											onChange={(name, value) => setCaseId(value)}
											className="bg-white"
										/>
										<Select
											label="Business Model"
											required
											default={phase}
											valid={phase ? true : validatePhase}
											options={[
												{ label: 'Feasibility', value: 'Feasibility' },
												{ label: 'Viability', value: 'Viability' },
												{ label: 'Desirability', value: 'Desirability' },
												{ label: 'Adoptability', value: 'Adoptability' },
												{ label: 'Compliance & Regulatory', value: 'Compliance & Regulatory' }
											]}
											onChange={(name, value) => setPhase(value)}
											className="bg-white"
										/>
									</Grid>
								</div>

								<div className="bg-gradient-to-r from-gray-50 to-gray-100 p-4 rounded-lg">
									<h3 className="text-lg font-semibold mb-4">Risk Assessment</h3>
									<Grid cols="2" className="gap-6">
										<AssumptionMapping
											setImportance={setImportance}
											setUnknown={setUnknown}
											ref={divRef}
											unknown={unknown}
											importance={importance}
										/>
										<div className="space-y-6">
											<RangeSlider
												min={1}
												max={100}
												values={[importance]}
												onChange={(e) => setImportance(e[0])}
												label="Importance"
												className="bg-white"
											/>
											<RangeSlider
												min={1}
												max={100}
												values={[unknown]}
												onChange={(e) => setUnknown(e[0])}
												label="Unknown Factor"
												className="bg-white"
											/>
											<RangeSlider
												min={1}
												max={30}
												values={[risk]}
												onChange={(e) => setRisk(e[0])}
												label="Overall Risk"
												className="bg-white"
											/>
										</div>
									</Grid>
								</div>
							</div>
						</Card>

						<div className="flex justify-end gap-4 mt-6">
							<Button
								text="Cancel"
								color="red"
								outline
								className="px-6 py-2 rounded-lg"
								action={() => context.modal.hide(true)}
							/>
							<Button
								text={props?.isUpdate ? 'Update' : 'Save'}
								color="brand"
								className="px-6 py-2 rounded-lg bg-gradient-to-r from-brand-400 to-brand-600 text-white"
								action={!loading && addHypothesis}
							/>
						</div>
					</div>
				</div>
			</div>
		</CSSTransition>
	)
}
