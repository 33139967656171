import axios from 'axios';
import { Card, Form, Grid, Icon, Loader, SubNavbar, Tooltip, useLocation } from 'components/lib';
import Spacer from 'components/spacer/spacer';
import React, { Fragment, useEffect, useState } from 'react';
import { LogoCard } from "../../components/card/projectcard";
import AllIntegrations from "./integrations/AllIntegrations";


export const Integration = () => {
	const [product, setProduct] = useState(null);
	const [integration, setIntegration] = useState({});
	const [loading, setLoading] = useState(false);
	const path = window.location.pathname;

	// Remove '/integration/' and extract the product_id
	const product_id = path.replace('/integration/', '');


	useEffect(() => {
		const fetchData = async () => {
			await getProduct();
			await getIntegrations();
		};
		fetchData();
	}, []);

	const getIntegrations = async ()=> {
		if (product_id) {
			setLoading(true);
			try {
				const res = await axios.get(`/api/integration/${product_id}`);
				if (res.data) {
					setIntegration(res.data.data);
				}
			} catch (err) {
				console.error('Error fetching product', err);
			} finally {
				setLoading(false);
			}
		}
	}
	const getProduct = async () => {
		if (product_id) {
			setLoading(true);
			try {
				const res = await axios.get(`/api/product/${product_id}`);
				if (res.data) {
					setProduct(res.data.data);
				}
			} catch (err) {
				console.error('Error fetching product', err);
			} finally {
				setLoading(false);
			}
		}
	};



	return (
		<Fragment>
			{loading && <Loader fullScreen />}
			{product && (
				<LogoCard
					title={product.name}
					subtitle={product.description}
					logo={product.logo}
				/>
			)}
			<Spacer height="h-[1.188rem]" />
			<SubNavbar active="integration" id={product_id} />
			<Spacer height="h-[1.125rem]" />
			<AllIntegrations getIntegrations={getIntegrations} integration={integration} product_id={product_id}/>
		</Fragment>
	);
};
