import React, { useState } from 'react'
import { Range, getTrackBackground } from 'react-range'
import { Loader, Card, useNavigate, DropdownMenu, Icon, Button, Logo, Animate } from 'components/lib'
import ChatGPT from 'components/chatgptPrompt'
import { useEffect } from 'react'
import axios from 'axios'


export function ProjectCard(props) {
	const navigate = useNavigate()
	let user = JSON.parse(localStorage.getItem('user'))
	// onClick={() => navigate('/insight')}
	return (
		<div className="min-h-full cursor-pointer" onClick={() => navigate(`/singleproject/${props?.id}`)}>
			<Card className="hover:shadow-inner border-brand-400 border bg-white bg-opacity-50 flex items-center text-left gap-[1.438rem] p-2 rounded-md shadow-lg">
				<div className='w-full h-full'>
					<img className="max-w-[5rem] h-full rounded bg-gray object-cover " alt="" src={process.env.REACT_APP_IMAGE_URL + props.image} />
				</div>
				<h1 className="text-[0.875rem] w-full h-full text-brand-400 leading-[1.063rem]" onClick={() => navigate(`/singleproject/${props?.id}`)}>
					{props.title}
				</h1>
				<>
					<div className="absolute right-[0.2rem] top-0">
						{(props?.teamLead || user?.permission === 'admin') && <DropdownMenu onEdit={() => props?.onEdit(props)} onDelete={() => props?.onDelete(props)} className={'!mt-0'} editTitle={'Edit Product'} deleteTitle={'Delete Product'} />}
					</div>
					{props.favourite ? (
						<Animate type='pop'>
							<a className="absolute right-[0.5rem] bottom-[0.5rem]" onClick={props.removeStar}>
								<Icon color='derisky-rose' image='heart' />
							</a>
						</Animate>
					) : (
						<Animate type='pop'><a className="absolute right-[0.5rem] bottom-[0.5rem]" onClick={props.addStart}>
							<Icon className="text-derisky-rose opacity-50" image='star' />
						</a></Animate>
					)}
				</>

				{props.loading ? <Loader /> : props.children}
			</Card>
		</div>
	)
}
export function DashboardCard(props) {
	const bgcolors = [
		{ bg: 'bg-gradient-to-r from-sky-400/75 to-blue-500/75', txt: 'text-white' },
		{ bg: 'bg-gradient-to-r from-fuchsia-60/75 to-purple-600/75', txt: 'text-white' },
		{ bg: 'bg-gradient-to-r from-blue-300/75 to-blue-800/75', txt: 'text-white' },
		{ bg: 'bg-gradient-to-r from-violet-600/75 to-indigo-600/75', txt: 'text-white' },
		{ bg: 'bg-gradient-to-r from-rose-700/75 to-pink-600/75/75', txt: 'text-white' },
		{ bg: 'bg-gradient-to-r from-slate-500/75 to-slate-800', txt: 'text-white' },
		{ bg: 'bg-gradient-to-r from-slate-900/75 to-slate-700', txt: 'text-white' },
		{ bg: 'bg-gradient-to-r from-indigo-500/75 to-blue-500/75', txt: 'text-white' },
		{ bg: 'bg-gradient-to-r from-blue-300/75 to-blue-800/75', txt: 'text-white' },
		{ bg: 'bg-gradient-to-r from-rose-400/75 to-orange-300/75', txt: 'text-white' },
	]
	const radomNumber = Math.floor(Math.random() * (bgcolors.length));
	console.log(radomNumber);
	const myColor = bgcolors[radomNumber];
	const myColorString = " " + myColor.bg + " " + myColor.txt;


	return (
		<div className='bg-brand-400 rounded-3xl'>
			<div key={props.title + props.subtitle} className={"p-5 flex flex-col  bg-opacity-90 rounded-3xl inset-0 uppercase " + myColorString}>
				<div className='px-6 py-10 bg-gradient-to-r from-white/30 to-derisky-lila/0  w-full h-full rounded-xl'>
					<p className='opacity-90'>{props.subtitle}</p>
					<hr className='bg-gradient-to-r from-white/30 to-brand-400/0 border-0 h-0.5' />
					<h1 className="text-4xl sm:text-4xl xl:text-5xl mt-12 font-light">{props.title}</h1>
					<div className="flex flex-row justify-between group-hover:text-gray-200">
						{props.loading ? <Loader /> : props.children}
					</div>
				</div>
			</div></div>
	)
	/*return (
		<div>
			{props.component === 'large' && (
				<Card className="flex items-center justify-center bg-gray min-h-[10.75rem] flex-col p-4 shadow-md">
					<h1 className="text-[3.125rem]  leading-[3.813rem] font-bold ">{props.title}</h1>
					<h1 className="text-[1.125rem]   leading-[1.375rem]">{props.subtitle}</h1>
					{props.loading ? <Loader /> : props.children}
				</Card>
			)}
			{props.component === 'medium' && (
				<Card className="flex items-center justify-center min-h-[3rem] bg-gray p-[0.54rem]">
					<h1 className="text-[3.125rem]  leading-[3.813rem] font-bold ">{props.title}</h1>
					{props.loading ? <Loader /> : props.children}
				</Card>
			)}
		</div>
	)*/
}

export function LogoCard(props) {
	return (
		<div className="flex items-center justify-between">
			<div className="flex items-center">
				<img className="shadow-md h-48 rounded-md mr-4" alt="" src={process.env.REACT_APP_IMAGE_URL + props.logo} />
				<div>
					<h1 className="text-[1.125rem ] mb-4 text-brand-400 leading-[1.375rem] font-bold flex gap-1">{props.title}</h1>
					<p className="text-[1.125rem ]  leading-[1.375rem] font-normal ">{props.subtitle}</p>
				</div>
			</div>

			<div className="mt-2 border border-brand-400 text-brand-400 rounded hover:shadow-inner shadow-lg flex pt-2 p-2 pr-5 text-xs self-center content-center cursor-pointer"
				onClick={props?.wizardClick}
			>
				Create Wizard
			</div>

		</div>
	)
}

export function PageTitle(props) {
	return (
		<div className="flex items-center gap-1 ">
			<Logo color />
		</div>
	)
}
export function HypothesisCard(props) {
	return (
		<Card className="bg-white rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl hover:scale-[1.02] group">
			<div className="flex items-center justify-center gap-6 py-6">
				<div className="bg-gradient-to-br from-brand-400 to-brand-500 rounded-lg w-16 h-16 flex items-center justify-center p-4 transition-colors duration-300 group-hover:from-brand-500 group-hover:to-brand-600">
					<img src={props.image} className="w-full h-full filter brightness-0 invert object-contain" alt="" />
				</div>
				<div className="flex flex-col gap-3">
					<h1 className="text-xl font-bold text-gray-800 group-hover:text-brand-500 transition-colors duration-300">{props.title}</h1>
					<p className="text-sm text-gray-600 group-hover:text-gray-700 transition-colors duration-300">{props.subtitle}</p>
				</div>
				{props.loading ? <Loader /> : props.children}
			</div>
		</Card>
	)
}

const STEP = 0.1
const MIN = 1
const MAX = 100

class RangeSlider extends React.Component {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props)
		this.state = {
			values: [30]
		}
	}

	render() {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					flexWrap: 'wrap',
					flexDirection: 'column',
					marginTop: '1.5em'
				}}>
				<div className="flex items-center flex-wrap gap-2 w-full">
					{/* <div>{this.props.label}</div> */}
					<div>
						<h1 className={`text-[0.875rem] leading-[1.125rem] w-full font-normal uppercase text-light_blac min-w-[8rem] ${this.props.className}`}>{this.props.label}</h1>
					</div>
					<Range
						values={this.props?.values ?? [30]}
						step={0.1}
						min={this.props?.min}
						max={this.props?.max}
						onChange={(values) => {
							this.setState({ values })
							this.props?.onChange(values)
						}}
						renderTrack={({ props, children }) => (
							<div
								onMouseDown={props.onMouseDown}
								onTouchStart={props.onTouchStart}
								className="flex w-[17.5rem]"
								style={{
									...props.style
									//display: 'flex',
									//width: '20rem'
								}}>
								<div
									ref={props.ref}
									style={{
										height: '10px',
										width: '100%',
										borderRadius: '4px',
										background: getTrackBackground({
											values: this.props?.values ?? [50],
											colors: ['#4e8ed2', '#ccc'],
											min: this.props?.min,
											max: this.props?.max
										}),
										alignSelf: 'center'
									}}>
									{children}
								</div>
							</div>
						)}
						renderThumb={({ props, isDragged }) => (
							<div className="h-[22px] w-[22px] rounded-full bg-white flex justify-center items-center shadow" {...props}>
								<div
									style={{
										position: 'absolute',
										top: '-28px',
										color: '#fff',
										fontWeight: 'bold',
										fontSize: '14px',
										fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
										padding: '4px',
										borderRadius: '4px',
										backgroundColor: '#548BF4'
									}}>
									{this.props?.values[0]?.toFixed(1) + '%'}
								</div>
								<div
									style={{
										height: '16px',
										width: '5px',
										backgroundColor: isDragged ? '#548BF4' : '#CCC'
									}}
								/>
							</div>
						)}
					/>
				</div>
			</div>
		)
	}
}
export default RangeSlider

export function ExperimentCard(props) {
	const user = JSON.parse(localStorage.getItem('user'))
	const [generateResponse, setGenerateResponse] = useState(false)
	const [product, setProduct] = useState()
	console.log('======props', props)

	useEffect(() => {
		if (props?.hypothesis?.product_id) {
			getProduct(props?.hypothesis?.product_id)

		}
	}, [props])

	const getProduct = async (id) => {
		if (props) {
			await axios({
				method: 'get',
				url: `/api/product/${id}`
			})
				.then((res) => {
					if (res.data) {
						setProduct(res.data.data)
					}
				})
				.catch((err) => {
					console.log('err', err)
				})
		}
	}
	return (
		<Card className="leading-[1.813rem] flex flex-col gap-[2rem] mb-0">
			<h1 className="text-[1.5rem]  leading-[1.813rem]">{props?.hypothesis?.statement}</h1>
			{props?.hypothesis?.description && <h1 className="max-w-[41rem]">{props?.hypothesis?.description}</h1>}
			<div className="flex gap-6 sm:gap-10 flex-wrap">
				<div className="flex mr-2 items-center">
					<h1 className="text-[0.875rem] leading-[1.125rem] font-normal uppercase text-light_blac mr-2">Business Model: </h1>
					<h1 className="font-bold">{props?.hypothesis?.phase}</h1>
				</div>
				<div className="flex mr-2 items-center">
					<h1 className=' mr-2'>Risk:</h1>
					<h1 className="font-bold">{props?.hypothesis?.risk}%</h1>
				</div>
				<div className="flex mr-2 items-center">
					<h1 className=' mr-2'>Importance: </h1>
					<h1 className="font-bold"> {props?.hypothesis?.importance}%</h1>
				</div>
				<div className="flex mr-2 items-center">
					<h1 className=' mr-2'> Unknown: </h1>
					<h1 className="font-bold">{props?.hypothesis?.unknown}%</h1>
				</div>

			</div>
			<div className="flex gap-6 sm:gap-10 flex-wrap">
				<Button text={'Give me a recommendation'}
					className={`inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2) !w-[18rem] ${user?.plan === 'free' && 'opacity-50'}`}
					medium
					color="blue"
					rounded
					// action={() => { if (user?.plan != 'free') window.location.href = 'http://Derisky.ai' }}
					action={() => {
						setGenerateResponse(true);
					}}
				/>
			</div>
			{generateResponse && <ChatGPT product={product} hypothesis={props?.hypothesis} />}
		</Card>
	)
}
