import RangeSlider from 'components/card/projectcard'
import { ConfidenceSlider, ConfidenceSliderList } from 'components/confidencesilder/confidenceslider'
import { Button, DateInput, Grid, Switch, TextInput } from 'components/lib'
import Spacer from 'components/spacer/spacer'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { IoMdClose } from 'react-icons/io'
import { CSSTransition } from 'react-transition-group'
import Style from '../../components/form/form.tailwind.js'
const ConfidenceListModal = (props) => {
	const [confidence, setConfidence] = useState(1)
	const [validLearning, setValidLearning] = useState(true)
	const [validRealEndDate, setValidRealEndDate] = useState(true)
	const [realEndDate, setRealEndDate] = useState('')
	const [insight, setInsight] = useState(false)
	const [learning, setLearning] = useState('')
	const [learningError, setLearningError] = useState('')
	const [support, setSupport] = useState(false)
	const [unClear, setUnclear] = useState(false)
	const [refuse, setRefuse] = useState(false)

	const handleValidation = () => {
		let isValid = true
		if (realEndDate?.trim().length === 0) {
			isValid = false
			setValidRealEndDate(false)
		} else {
			setValidRealEndDate(true)
		}

		if (learning?.trim().length === 0) {
			isValid = false
			setLearningError(false)
		} else {
			setLearningError(true)
		}

		return isValid
	}

	useEffect(() => {
		if (props?.data) {
			setRealEndDate(props?.data?.real_end_date)
			setConfidence(props?.data?.confidence)
			setInsight(props?.data?.insight == 1 ? true : false)
			setRefuse(props?.data?.refute)
			setSupport(props?.data?.support)
			setUnclear(props?.data?.unclear)
		}
	}, [props?.data])
	console.log('======props?.dataprops?.data', props?.data, { insight, realEndDate, learning, confidence, support, refuse, unClear })

	return (
		<CSSTransition in appear timeout={0} classNames="modal">
			<div className="modal">
				<div className="modal-content-confidence p-4 rounded overflow-hidden">
					<div className="flex justify-end" onClick={(e) => props?.onClose()}>
						<IoMdClose className="text-2xl text-black cursor-pointer" />
					</div>
					<Spacer height={'h-8'} />
					<DateInput
						label=" Real end date"
						type="date"
						value={realEndDate}
						required="true"
						valid={realEndDate ? true : validRealEndDate}
						onChange={(name, value, valid) => {
							setRealEndDate(value)
						}}
					/>
					<div className="flex mb-3">
						<Switch label="Insight" hypothesis={true} default={insight} onChange={(name, value, valid) => setInsight(value)} />
					</div>
					<Grid cols={1}>
						<ConfidenceSlider values={props?.data} insight={insight} support={setSupport} refute={setRefuse} unclear={setUnclear} />
					</Grid>
					<div className="flex items-center flex-wrap">
						<div>
							<RangeSlider min={0} max={100} values={[confidence ? parseFloat(confidence) * 100 : 0]} onChange={(e) => setConfidence(e[0] / 100)} label={'Confidence List Slider'} className={'text-[0.9rem]'} />
						</div>
					</div>
					<Spacer height={'h-8'} />
					<TextInput
						label="Add Learnings"
						type="text"
						value={learning}
						required="true"
						valid={learning ? true : learningError}
						onChange={(name, value, valid) => {
							setLearning(value)
						}}
					/>
					<Spacer height={'h-8'} />
					<div className="flex justify-between">
						<div></div>
						<div className="flex gap-[0.5rem]">
							<Button className="inline-block rounded " text={'Close'} color="red" width="true" action={props?.onClose} outline />
							<Button
								className="inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] w-[0.875]"
								text={props?.isUpdate ? 'Update' : 'Save'}
								color="blue"
								width="true"
								action={() => {
									if (handleValidation()) {
										props?.confidence({ insight, realEndDate, learning, confidence, support, refuse, unClear })
									}
								}}
								rounded
							/>
						</div>
					</div>
					{/* <Grid cols="1">
						<ConfidenceSliderList
							title="Confidence List Slider"
							onClick={(e) => {
								setConfidence(e)
								props?.confidence(e)
							}}
							value={confidence}
						/>
					</Grid> */}
				</div>
			</div>
		</CSSTransition>
	)
}

export default ConfidenceListModal
