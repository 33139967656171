import {useNavigate} from 'react-router-dom'
import ButtonPrimary from './ButtonPrimary'
import PropTypes from 'prop-types'

const Decisions = ({className = ''}) => {
	const navigate = useNavigate()
	return (
		<section
			className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[40.7px] lg:pb-[140.7px] box-border max-w-full shrink-0 text-left text-37xl text-gray1-100 font-plus-jakarta-sans mq450:pb-[59px] mq450:box-border mq1150:pb-[91px] mq1150:box-border ${className}`}>
			<div className="w-[1160px] flex flex-col items-start justify-start gap-[56px] max-w-full mq800:gap-[28px]">
				<div className="self-stretch flex flex-row items-start justify-between max-w-full gap-[20px] mq1150:flex-wrap flex-wrap lg:flex-nowrap">
					<h1 className="m-0 w-[710px] text-[2rem] lg:text-[3.5rem] relative text-start text-inherit leading-[38.4px] lg:leading-[67.2px] font-medium font-inherit inline-block shrink-0 max-w-full mq450:text-15xl mq450:leading-[40px] mq800:text-26xl mq800:leading-[54px]">
						Make data-driven portofolio decisions
					</h1>
					<div className="flex flex-col items-start justify-start pt-[42px] lg:pt-[82px] px-0 pb-0">
						<ButtonPrimary className="rounded-3xl  bg-[#6145F7] hover:!bg-[#422db6] text-white" onClick={() => navigate('/signup')} />
					</div>
				</div>
				<div className="flex flex-row justify-center flex-wrap gap-[0.5rem] lg:flex-nowrap w-full">
					<img src="/Add/derisky.svg" alt="RAG" />
				</div>
			</div>
		</section>
	)
}

Decisions.propTypes = {
	className: PropTypes.string
}

export default Decisions
